<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.id ? data.name : "Новая запись в справочнике [" + dir.text + "]" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row v-if="modelForm">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="pb-0">
          <a-form-model v-model="data" :model="getFormModel([el.name])" :readonly="!canEdit" :config="defaults" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :disabled="!(canEdit || !id)"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true" :disabled="!canEdit"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, keyDetect, getAccess, genModel } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, keyDetect, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    defaults: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    dir: {
      type: Object,
      default: () => {},
    },
    api: String,
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.dirs,
      removeDialogShow: false,
      modelForm: null,
      watchField: null,
      needCalcModel: 1,
    };
  },
  computed: {
    model: {
      get() {
        if (!this.needCalcModel) return;
        const name = this.dir?.name || null;
        if (!name) return {};
        const m = JSON.parse(JSON.stringify(this.m));

        let model = this.getModelList(this.m, name + "Form", true);
        let fieldsForm = [];
        let modelForm = [];
        model.forEach((el) => {
          if (!this.id && el?.hiddenCreate) {
            el.hidden = true;
          }
          fieldsForm.push(el.name);
        });
        this.watchField = null;
        if (m?.[name + "Config"]) {
          const f = m[name + "Config"].configField;
          let config;
          let def = m[name + "Config"]?.default;
          if (f) {
            this.watchField = f;
            config = m[name + "Config"].config.find((el) => {
              if (Array.isArray(el.value)) return el.value.includes(this.data[f]);
              return el.value == this.data[f];
            });
            if (config === undefined) config = def;
          } else {
            config = def;
          }
          const fields = config?.fields ? config.fields.split(",") : [];
          fieldsForm = config?.fieldsForm ? config.fieldsForm.split(",") : fieldsForm;
          if (fields.length) {
            this.fillFormValidators(model.filter((el) => fields.includes(el.name)));

            // model = model.filter((el) => fields.includes(el.name));
          }
        }
        fieldsForm.forEach((field) => {
          let s = field.split("#");
          modelForm.push({ name: s[0], size: s[1] || 12 });
        });
        this.modelForm = modelForm;
        return model;
      },
    },
    canEdit() {
      let res =
        this.getAccess("dirs." + this.dir.name + ".edit") ||
        (this.getAccess("dirs." + this.dir.name + ".create") && (this.data.createdby_id == this.$root.profile.id || !this.id));
      if (!res) {
        if ((new Date() - new Date(this.data.createdon)) / 1000 / 60 < 15) res = true;
      }
      return res;
    },
    canDelete() {
      let res = this.getAccess("dirs." + this.dir.name + ".delete");
      return res;
    },
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        if (!this.watchField) return;
        if (newVal[this.watchField] !== oldVal[this.watchField]) {
          this.needCalcModel++;
        }
      },
      deep: true,
    },
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  created() {
    this.updateData(this.id);
    if (!this.id) {
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  methods: {
    async submit() {
      if (await this.validateAll(this.data)) {
        //console.log('save', this.data);
        await this.post(this.data, this.api);
        return true;
      } else if (this.keys?.ctrl && this.$root.profile.role === 1000) {
        await this.post(this.data, this.api);
        return true;
      }

      return false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$store.dispatch("config/fitchDirs", this.dir.name);
      this.$emit("input");
    },
    afterSave(data, status) {
      this.$store.dispatch("config/fitchDirs", this.dir.name);
      if (status) this.$emit("input");
    },
  },
};
</script>
